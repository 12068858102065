import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/eb-5-investor-visa.jpg';
import CtaDeck from '../components/common/cta-deck';
import FeaturedIn from '../components/common/featured-in';
import Testimonials from '../components/common/testimonials';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { INVESTOR_VISA_IMMIGRATION_TESTIMONIALS } from '../data/investor-visa-usa-immigration/testimonials';
import ReviewDeck from '../components/common/review-deck';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'EB-5 Investor Visa USA | Requirements & Application | Pandev Law',
  description:
    'Searching for a trusted and experienced EB-5 Investor Visa immigration lawyer? Pandev Law is here to help you. Talk to us now!',
  keywords:
    'investor immigration,investor visa USA,entrepreneur visa USA,US green card by investment',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "EB-5 Investor Visa USA | Requirements & Application | Pandev Law",
    "description": "Searching for a trusted and experienced EB-5 Investor Visa immigration lawyer? Pandev Law is here to help you. Talk to us now!",
    "url": "https://www.pandevlaw.com/eb-5-visa",
    "image": "https://pandevlaw.com/img/eb-5-investor-visa.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/eb-5-visa/"
  },
  "headline": "EB-5 Visa USA Services",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function EB5Visa({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero title="EB-5 Visa USA Services" img={heroImg}>
        <p className="text-lead mb-5 mb-md-6">
          This is our dedicated EB-5{' '}
          <Link to="/investor-visa-usa-immigration/">investor visa</Link> page. Below you
          will find information about the EB-5 visa USA application process, requirements,
          processing times, and EB-5 visa program in general. As an experienced EB-5
          investor visa lawyer I will listen to your short and long-term goals, advise you
          on the best immigration strategy for your specific needs and help you throughout
          your EB-5 visa USA application process.
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="EB-5 Visa Testimonials"
        testimonials={INVESTOR_VISA_IMMIGRATION_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/what-is-eb-5-investor-visa.jpg"
                alt="EB-5 Investor Visa"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">
                What is an EB-5 investor visa?
              </h2>
              <p className="text-body mb-6">
                The EB-5 visa program is the best solution for investors with significant
                financial resources who wish to obtain a green card through investment.
                The EB-5 visa USA program presents two investment options for the investor
                visa applicant: Regional Center or Direct Investment. You may learn more
                about these two different EB-5 visa programs in the requirements section
                below.
                <br />
                <br />
                The biggest advantage of the EB-5 investor visa program is that it leads
                to a green card which allows the successful EB-5 investor visa applicant
                to permanently live and work in the United States. The program allows the
                spouse and unmarried children under 21 of an EB-5 investor visa holder to
                apply for a green card and move to the United States with the main EB-5
                investor visa applicant.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/eb-5-visa-requirements.jpg"
                alt="EB-5 visa requirements"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">EB-5 visa requirements</h2>
              <p className="text-body mb-6">
                The EB-5 visa program has requirements which the EB-5 investor visa
                applicant must meet in order to successfully obtain an investor green
                card.
              </p>
              <div className="accordion" id="eb5VisaRequirementsAccordion">
                <div className="mb-5">
                  <div id="eb5VisaRequirementsTargetZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5VisaRequirementsTargetTargetZero"
                      aria-expanded="true"
                      aria-controls="eb5VisaRequirementsTargetTargetZero">
                      <strong>EB-5 visa minimum investment amount</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5VisaRequirementsTargetTargetZero"
                    className="collapse show"
                    aria-labelledby="eb5VisaRequirementsTargetZero"
                    data-parent="#eb5VisaRequirementsAccordion">
                    <p className="text-body mb-4">
                      The first EB-5 visa requirement is that the applicant must make a
                      specific minimum capital investment to qualify for the EB-5 visa USA
                      program. The capital investment can include cash, cash equivalents,
                      inventory, or tangible property. As of November 21, 2019, the EB-5
                      minimum investment amounts are $900,000 for a project located in a
                      Targeted Employment Area (TEA) or $1,800,000 for a project located
                      outside a Targeted Employment Area (TEA). These minimum investment
                      amounts apply equally to Regional Center and Direct Investment EB-5
                      visa USA programs.
                      <br />
                      <br />
                      You may be wondering, “Wasn’t the minimum investment amount for an
                      EB-5 visa $500,000?” Yes, in fact, it was. However, on November 21,
                      2019, there was an EB-5 visa program change which changed the EB-5
                      visa requirements and increased the minimum EB-5 investment amount
                      to $900,000 or $1,800,000 depending on whether the investment
                      project is located in a TEA.
                      <br />
                      <br />
                      The EB-5 visa requirements also state that the funds invested in the
                      investor visa project must be at risk.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="eb5VisaRequirementsTargetOne">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5VisaRequirementsTargetTargetOne"
                      aria-expanded="false"
                      aria-controls="eb5VisaRequirementsTargetTargetOne">
                      <strong>Job creation EB-5 visa requirement</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5VisaRequirementsTargetTargetOne"
                    className="collapse"
                    aria-labelledby="eb5VisaRequirementsTargetOne"
                    data-parent="#eb5VisaRequirementsAccordion">
                    <p className="text-body mb-4">
                      The second EB-5 visa requirement is that the investment must create
                      a minimum amount of US jobs. To qualify for the EB-5 investor visa
                      program, the investor visa applicant is required to create at least
                      10 full-time jobs for US workers. The EB-5 visa program requirements
                      state that the jobs under the EB-5 investor visa category must be
                      created either before the applicant files the I-526 petition or the
                      applicant must explain how investment will create the 10 jobs during
                      the EB-5 visa investor’s 2-year conditional permanent residence
                      period. The jobs created under the EB-5 investor visa category must
                      be maintained throughout the EB-5 visa USA investor’s 2-year
                      conditional permanent residence period until she files the I-829
                      petition to remove conditions.
                      <br />
                      <br />
                      The jobs created under the EB-5 visa program are categorized as
                      direct, indirect or induced. A direct job means that the created job
                      is an actual position for qualified US workers. A qualified US
                      worker is generally an individual who is a US citizen or a permanent
                      resident. Individuals who do not qualify as a qualified worker
                      include the EB-5 visa USA investor herself, the EB-5 visa USA
                      applicant’s family (including spouse and children), and foreign
                      nationals in a non-immigrant visa status. The jobs under the EB-5
                      visa program must be created in the business in which the EB-5
                      investor has made an investment. The EB-5 visa program requirements
                      also state that the positions must be full-time (at least 35 hours
                      per week). Direct jobs can be used to meet the EB-5 visa
                      requirements regardless of whether the investor chooses the Regional
                      Center or the Direct Investment option.
                      <br />
                      <br />
                      Indirect and induced jobs, on the other hand, may only be counted to
                      meet the job creation EB-5 visa requirement if the investor chooses
                      the Regional Center option. Indirect jobs that may be counted under
                      the EB-5 visa program include jobs created in businesses affiliated
                      or associated with an EB-5 Regional Center, such as businesses
                      supplying goods or services to the EB-5 Regional Center. Induced
                      jobs that may be counted under the program include jobs created in
                      the community where the Regional Center is located. These induced
                      jobs may be counted if the jobs are created in the community due to
                      the extra spending by workers associated with the EB-5 Regional
                      Center.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="eb5VisaRequirementsTargetTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5VisaRequirementsTargetTargetTwo"
                      aria-expanded="false"
                      aria-controls="eb5VisaRequirementsTargetTargetTwo">
                      <strong>Lawful source of funds EB-5 visa requirement</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5VisaRequirementsTargetTargetTwo"
                    className="collapse"
                    aria-labelledby="eb5VisaRequirementsTargetTwo"
                    data-parent="#eb5VisaRequirementsAccordion">
                    <p className="text-body mb-4">
                      The third EB-5 visa requirement is that the funds invested by the
                      EB-5 investor must come from a lawful source. This means that the
                      EB-5 investor visa applicant must demonstrate the lawful source of
                      all funds used for the investment. The investor must trace the
                      source of funds from the time they were earned to their investment
                      into the EB-5 visa USA project.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="eb5VisaRequirementsTargetThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5VisaRequirementsTargetTargetThree"
                      aria-expanded="false"
                      aria-controls="eb5VisaRequirementsTargetTargetThree">
                      <strong>
                        Investment in a new commercial enterprise EB-5 visa requirement
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="eb5VisaRequirementsTargetTargetThree"
                    className="collapse"
                    aria-labelledby="eb5VisaRequirementsTargetThree"
                    data-parent="#eb5VisaRequirementsAccordion">
                    <p className="text-body mb-4">
                      The fourth EB-5 visa requirement is that the investment be made in a
                      qualifying investment, as described in 8 C.F.R. § 204.6. The program
                      requires that your investment be made in a “new” commercial
                      enterprise. New Commercial Enterprise means a for-profit activity,
                      created after November 29, 1990 and for the purpose of conducting an
                      ongoing lawful business, including partnership, joint venture, sole
                      proprietorship, business trust, holding company, corporation, or
                      other entity that is publicly or privately owned.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/eb-5-visa-program-options.jpg"
                alt="EB-5 Visa Program Options: Regional Center or Direct Investment"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                EB-5 Visa Program Options: Regional Center or Direct Investment
              </h2>
              <p className="text-body mb-5">
                The EB-5 visa program presents EB-5 visa USA investors with two options:
                Regional Center or Direct Investment.
              </p>
              <div className="accordion" id="eb5VisaProgramOptions">
                <div className="mb-5">
                  <div id="eb5VisaProgramOptionsHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5VisaProgramOptionsCollapseZero"
                      aria-expanded="true"
                      aria-controls="eb5VisaProgramOptionsCollapseZero">
                      <strong>EB-5 visa program option 1 – Regional Center</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5VisaProgramOptionsCollapseZero"
                    className="collapse show"
                    aria-labelledby="eb5VisaProgramOptionsHeadingZero"
                    data-parent="#eb5VisaProgramOptions">
                    <p className="text-body mt-4 mb-5">
                      The EB-5 investor visa rules permit an EB-5 visa USA investor may
                      choose an EB-5 Regional Center as her EB-5 investor visa investment
                      vehicle. A Regional Center is a United States Citizenship and
                      Immigration Services (USCIS) authorized organization which sponsors
                      capital investment projects for EB-5 visa USA investors to invest
                      in. Regional Centers are public or privately owned entities that
                      help increase the domestic capital, create job opportunities, and
                      improve local and regional productivity and economic growth.
                      <br />
                      <br />
                      Regional Centers are appealing as an investment vehicle to EB-5
                      investor visa applicants because they make it easier to satisfy the
                      job creation EB-5 visa requirement and do not require the investor’s
                      active involvement in the management of the investment project. The
                      EB-5 Regional Center should have a detailed plan on how the project
                      will satisfy all visa requirements. EB-5 Regional Centers can use
                      indirect and induced jobs to meet the job creation requirement. When
                      choosing the EB-5 Regional Center option, the EB-5 visa USA
                      applicant is still required to make the minimum capital investment
                      and to prove the lawful source of invested funds.
                      <br />
                      <br />
                      <i>
                        <strong>
                          &gt; Who should choose the EB-5 Regional Center as their EB-5
                          visa investment vehicle?
                        </strong>
                      </i>
                      <br />
                      The EB-5 Regional Center option is perfect for EB-5 visa USA
                      investors who are more interested in obtaining permanent residence
                      in the United States than actually starting and running a business
                      in the United States.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="eb5VisaProgramOptionsHeadingOne">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5VisaProgramOptionsCollapseOne"
                      aria-expanded="false"
                      aria-controls="eb5VisaProgramOptionsCollapseOne">
                      <strong>L-1 Investor Visa USA Requirements</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5VisaProgramOptionsCollapseOne"
                    className="collapse"
                    aria-labelledby="eb5VisaProgramOptionsHeadingOne"
                    data-parent="#eb5VisaProgramOptions">
                    <p className="text-body mt-4 mb-5">
                      The EB-5 investor visa applicant may choose to start her own
                      business as her EB-5 visa USA investment vehicle. When choosing the
                      direct investment option, the investor must form a US business in
                      accordance with the EB-5 visa program regulations. The investor must
                      then make the minimum capital investment into the business. Per the
                      EB-5 visa program requirements, the investor’s business must create
                      at least 10 full-time jobs. Only direct jobs may be counted to
                      satisfy the job creation EB-5 visa requirement when choosing the
                      direct investment option. The EB-5 investor visa applicant may
                      choose any business to invest in. The key is that all requirements
                      be met by the investment. We have seen investors choose restaurants,
                      coffee shops, hotels, franchises, or small manufacturing companies,
                      among others, to qualify for an EB-5 investor visa.
                      <br />
                      <br />
                      There are two key differences between the Direct Investment and
                      Regional Center options. First, when choosing the Direct Investment
                      option, the investor should expect to have a much more hands-on
                      approach in the EB-5 visa USA project. The EB-5 investor visa
                      applicant should expect to be highly involved in the management of
                      the enterprise. Second, the Direct Investment option requires that
                      the EB-5 visa project create 10 direct jobs. Indirect and induced
                      jobs will not count in meeting the job creation EB-5 visa
                      requirement.
                      <br />
                      <br />
                      <i>
                        <strong>
                          &gt; Who should choose the EB-5 Direct Investment as their EB-5
                          visa investment vehicle?
                        </strong>
                      </i>
                      <br />
                      The Direct Investment option is perfect for EB-5 visa USA investors
                      who wish to start, operate, and actively manage a business in the
                      United States and use this business to qualify for an EB-5 investor
                      visa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/eb-5-visa-process.jpg"
                alt="EB-5 Investor Visa Process"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">EB-5 Investor Visa Process</h2>
              <p className="text-body mb-6">
                The EB-5 visa USA application process has four steps which the EB-5
                investor visa applicant needs to go through in order to obtain an EB-5
                investor visa.
              </p>

              <div className="accordion" id="eb5InvestorVisaProcessAccordion">
                <div className="mb-5">
                  <div id="eb5InvestorVisaProcessTargetZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5InvestorVisaProcessTargetTargetZero"
                      aria-expanded="true"
                      aria-controls="eb5InvestorVisaProcessTargetTargetZero">
                      <strong>
                        Step 1: Finding the right EB-5 investor visa project
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="eb5InvestorVisaProcessTargetTargetZero"
                    className="collapse show"
                    aria-labelledby="eb5InvestorVisaProcessTargetZero"
                    data-parent="#eb5InvestorVisaProcessAccordion">
                    <p className="text-body mb-4">
                      First, the EB-5 investor visa applicant must choose her EB-5 visa
                      USA project. The investor may choose either the Direct Investment or
                      Regional Center option as her investor visa investment vehicle. You
                      can find more information about these options in the section above.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="eb5InvestorVisaProcessTargetOne">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5InvestorVisaProcessTargetTargetOne"
                      aria-expanded="false"
                      aria-controls="eb5InvestorVisaProcessTargetTargetOne">
                      <strong>
                        Step 2: EB-5 visa USA cost (investment amount) and I-526 Petition
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="eb5InvestorVisaProcessTargetTargetOne"
                    className="collapse"
                    aria-labelledby="eb5InvestorVisaProcessTargetOne"
                    data-parent="#eb5InvestorVisaProcessAccordion">
                    <p className="text-body mb-4">
                      Second, the EB-5 investor must make the necessary capital
                      investment. The EB-5 visa USA investor must invest either $900,000
                      or $1,800,000 in the chosen project. You can find more information
                      about the EB-5 visa requirements including minimum investment
                      amounts in the above sections.
                      <br />
                      <br />
                      Once the necessary EB-5 investment is made, the investor visa
                      applicant must file Form I-526, Immigrant Petition by Alien
                      Investor, with USCIS. In this petition, the EB-5 visa USA applicant
                      must demonstrate how she has satisfied the requirements for initial
                      approval. The I-526 petition processing time with USCIS usually
                      takes around 20 months. Once a decision is made, USCIS will inform
                      the investor if her petition is approved or denied. Most EB-5
                      Regional Centers will refund the investor’s capital investment if
                      the I-526 petition is denied. However, such refund arrangements are
                      governed by the agreement between the EB-5 investor and the Regional
                      Center as the EB-5 visa program rules do not require refunds to be
                      made since the invested funds must be at risk.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="eb5InvestorVisaProcessTargetTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5InvestorVisaProcessTargetTargetTwo"
                      aria-expanded="false"
                      aria-controls="eb5InvestorVisaProcessTargetTargetTwo">
                      <strong>Step 3: 2-year conditional permanent residence</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5InvestorVisaProcessTargetTargetTwo"
                    className="collapse"
                    aria-labelledby="eb5InvestorVisaProcessTargetTwo"
                    data-parent="#eb5InvestorVisaProcessAccordion">
                    <p className="text-body mb-4">
                      Third, once the I-526 petition is approved, the EB-5 investor visa
                      applicant must apply to become a conditional permanent resident,
                      also known as conditional green card holder. To obtain the
                      conditional green card, the EB-5 visa USA applicant must either file
                      Form I-485, Application to Adjust Status, with USCIS, if the
                      applicant is in the United States, or Form DS-260, Immigrant Visa
                      Application, with a US consulate or embassy, if the applicant is
                      outside the United States. Once the application is approved, the
                      EB-5 investor will receive a conditional 2-year green card.
                      <br />
                      <br />
                      <i>
                        <strong>
                          &gt; EB-5 visa application process: Submitting Form I-485
                        </strong>
                      </i>
                      <br />
                      If the EB-5 investor visa applicant already has an I-526 petition
                      approved and is lawfully living in the United States, then the EB-5
                      visa USA applicant may file Form I-485 to adjust status to
                      conditional permanent resident in the United States.
                      <br />
                      <br />
                      The processing time for a Form I-485 adjustment of status
                      application takes between 6 to 12 months, largely depending on where
                      in the United States the EB-5 visa USA applicant resides. Once the
                      I-485 adjustment of status application is approved, the applicant
                      officially becomes a conditional green card holder. This conditional
                      permanent residence status is valid for 2 years.
                      <br />
                      <br />
                      <i>
                        <strong>
                          &gt; EB-5 visa application process: Submitting Form DS-260
                        </strong>
                      </i>
                      <br />
                      If the EB-5 investor visa applicant has an I-526 petition but lives
                      outside the United States, then the EB-5 visa USA applicant may file
                      Form DS-260 to obtain an immigrant visa to enter the United States
                      as conditional permanent resident. Form DS-260 is processed at a US
                      Consulate or Embassy in the EB-5 investor visa applicant’s country
                      of origin. The EB-5 visa program application process lays out two
                      steps for obtaining a Form DS-260 approval. The first step is for
                      the EB-5 investor visa applicant to submit Form DS-260 along with
                      all the necessary documents. The second step is attending the
                      interview at the US Consulate or Embassy which the EB-5 investor
                      visa applicant designated in her application.
                      <br />
                      <br />
                      Once Form DS-260 is approved, the EB-5 investor visa applicant
                      obtains an immigrant visa, which the applicant can use to enter the
                      United States as a conditional permanent resident. The EB-5 visa USA
                      applicant’s spouse and children under 21 can also obtain conditional
                      permanent resident status in the United States.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="eb5InvestorVisaProcessTargetThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5InvestorVisaProcessTargetTargetThree"
                      aria-expanded="false"
                      aria-controls="eb5InvestorVisaProcessTargetTargetThree">
                      <strong>Step 4: I-829 petition and removal of conditions</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5InvestorVisaProcessTargetTargetThree"
                    className="collapse"
                    aria-labelledby="eb5InvestorVisaProcessTargetThree"
                    data-parent="#eb5InvestorVisaProcessAccordion">
                    <p className="text-body mb-4">
                      The last step of the EB-5 visa application process is for the
                      applicant to remove conditions on her permanent resident status.
                      This is done by filling Form I-829, Petition by Investor to Remove
                      Conditions on Permanent Resident Status, with USCIS. Once the I-829
                      petition is approved, the EB-5 investor visa applicant has met all
                      the EB-5 visa program requirements.
                      <br />
                      <br />
                      The EB-5 visa USA applicant should generally present the following
                      documents during the I-829 process:
                    </p>
                    <ul className="list-bullets">
                      <li>
                        Documents evidencing that the EB-5 visa USA commercial enterprise
                        was formed.
                      </li>
                      <li>
                        Documents evidencing that the EB-5 capital investment was received
                        by the enterprise.
                      </li>
                      <li>
                        Documents evidencing that the EB-5 business was running for the
                        two-year conditional permanent residence period.
                      </li>
                      <li>
                        Documents evidencing that the EB-5 business created at least 10
                        full-time jobs.
                      </li>
                    </ul>
                    <p className="text-body mb-4">
                      The EB-5 visa program requires that the I-829 petition be submitted
                      90 days prior to the 2nd year anniversary of the date that the EB-5
                      investor visa applicant first received her conditional resident
                      status. If the EB-5 visa USA applicant fails to submit the I-829
                      petition in the required timeframe, USCIS will terminate the EB-5
                      investor visa applicant’s conditional resident status.
                      <br />
                      <br />
                      USCIS usually takes up to 21 months to process the I-829 petition
                      and to issue a permanent green card.
                      <br />
                      <br />
                      When USCIS approves the I-829 petition, the EB-5 immigrant visa
                      holder and her spouse and unmarried children under 21 obtain
                      permanent green cards issued for a 10-year period with the option to
                      renew them indefinitely. This means that the EB-5 investor visa
                      holder and her spouse and unmarried children under 21 can
                      permanently live and work in the US.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="eb5InvestorVisaProcessTargetFour">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5InvestorVisaProcessTargetTargetFour"
                      aria-expanded="false"
                      aria-controls="eb5InvestorVisaProcessTargetTargetFour">
                      <strong>Processing Time for EB-5 visa</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5InvestorVisaProcessTargetTargetFour"
                    className="collapse"
                    aria-labelledby="eb5InvestorVisaProcessTargetFour"
                    data-parent="#eb5InvestorVisaProcessAccordion">
                    <p className="text-body mb-4">
                      The processing times for each step of the EB-5 application process
                      vary. Please see below summary of general processing times for each
                      EB-5 investor visa USA step. We suggest you visit{' '}
                      <a
                        href="https://egov.uscis.gov/processing-times/"
                        target="_blank"
                        rel="noreferrer noopener">
                        USCIS’s processing times website
                      </a>{' '}
                      for the most uptodate processing time information.
                    </p>
                    <ul className="list-bullets">
                      <li>I-526 Petition: 20 months.</li>
                      <li>I-485 Application: from 6 to 12 months.</li>
                      <li>I-829 Petition: 28 months.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/eb-5-risks-benefits.jpg"
                alt="EB-5 Visa Risks and Benefits"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">EB-5 Visa Risks and Benefits</h2>
              <div className="accordion" id="eb5VisaRisksAndBenefits">
                <div className="mb-5">
                  <div id="eb5VisaRisksAndBenefitsHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5VisaRisksAndBenefitsCollapseZero"
                      aria-expanded="true"
                      aria-controls="eb5VisaRisksAndBenefitsCollapseZero">
                      <strong>EB-5 visa risks</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5VisaRisksAndBenefitsCollapseZero"
                    className="collapse show"
                    aria-labelledby="eb5VisaRisksAndBenefitsHeadingZero"
                    data-parent="#eb5VisaRisksAndBenefits">
                    <p className="text-body mt-4 mb-5">
                      The most common question EB-5 investor visa applicants ask about the
                      EB-5 visa program is, “Is EB-5 visa program safe” and in particular
                      “Are EB-5 visa USA Regional Centers safe?” As with any investment,
                      the EB-5 visa program has some risks. This is why we advise every
                      investor to conduct proper due diligence and work with qualified
                      professionals through each phase of the process. Below are some
                      points to consider before starting your EB-5 visa USA application
                      process:
                    </p>
                    <ul className="list-bullets">
                      <li>
                        Confirm with the Regional Center whether your investment will be
                        refunded if your I-526 petition is denied. This is particularly
                        important because EB-5 investor visa applicants are required to
                        invest the entire minimum EB-5 visa USA investment amount before
                        filing the I-526 petition.
                      </li>
                      <li>
                        Perform necessary due diligence on the Regional Center. Although
                        USCIS approves EB-5 Regional Centers as part of the EB-5 visa
                        program, this does not mean that USCIS endorses the Regional
                        Center’s activities. It also does not mean that the EB-5 Regional
                        Center has complied with US securities laws. Moreover, a poorly
                        managed Regional Center could run into financial problems and file
                        for bankruptcy. All of these factors could negatively impact your
                        EB-5 investment and put your EB-5 visa USA application at risk.
                      </li>
                    </ul>
                    <p className="text-body mt-4 mb-5">
                      Finding the right Regional Center project for your needs can be time
                      consuming. This is why we recommend you work with qualified
                      professionals throughout every step of the process. As your EB-5
                      visa lawyer, I will advise you throughout the entire EB-5 visa
                      application process and refer you to qualified professionals as
                      needed.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="eb5VisaRisksAndBenefitsHeadingOne">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#eb5VisaRisksAndBenefitsCollapseOne"
                      aria-expanded="false"
                      aria-controls="eb5VisaRisksAndBenefitsCollapseOne">
                      <strong>EB-5 visa benefits</strong>
                    </h2>
                  </div>
                  <div
                    id="eb5VisaRisksAndBenefitsCollapseOne"
                    className="collapse"
                    aria-labelledby="eb5VisaRisksAndBenefitsHeadingOne"
                    data-parent="#eb5VisaRisksAndBenefits">
                    <div className="mt-3">
                      <strong>
                        Using EB-5 visa program to start your own business in the United
                        States
                      </strong>
                      <p className="text-body mt-3">
                        One of the major advantages of the EB-5 visa program is the chance
                        to start your own business in the United States. The EB-5 visa
                        program Direct Investment option may be the best solution for you
                        if you wish to start a business in the United States as it
                        provides you with complete control over your investment and allows
                        you to manage your US business.
                      </p>
                    </div>
                    <div className="mt-3">
                      <strong>Using the EB-5 visa program to advance your career</strong>
                      <p className="text-body mt-3">
                        If you are a foreign professional who believes becoming a US
                        permanent resident will help you advance your career, then the
                        EB-5 visa program Regional Center option may be the best solution
                        for you. The Regional Center option does not require active
                        day-to-day management of your EB-5 investment. This will allow you
                        to continue to work in your field while your EB-5 investor visa
                        application is pending. Once your EB-5 visa USA application is
                        approved, you will no longer require visa sponsorship and may work
                        for any US employer.
                      </p>
                    </div>
                    <div className="mt-3">
                      <strong>EB-5 visa program benefits for school-aged children</strong>
                      <p className="text-body mt-3">
                        If you have school-aged children who wish to attend high school,
                        college, or university in the United States, the EB-5 visa program
                        could be a great solution for you. The EB-5 visa program rules
                        allow children under 21 to apply for a green card together with
                        the main applicant parent. Becoming an EB-5 green card holder has
                        additional benefits for school-aged children, such as access to
                        resident tuition fees and employment opportunities with US
                        employers anywhere in the United States.
                      </p>
                    </div>
                    <div className="mt-3">
                      <strong>
                        Obtaining US citizenship through the EB-5 visa program
                      </strong>
                      <p className="text-body mt-3">
                        One of the greatest benefits of the EB-5 visa program is that it
                        provides a road to obtaining a green card and ultimately to
                        becoming a US citizen. Many EB-5 immigrant visa investors apply to
                        the EB-5 visa program because they wish to become US citizens. The
                        EB-5 visa program may be the perfect solution for you, if your
                        goal is to become a US citizen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      <AbTestingDeck
        title="EB-5 Visa Testimonials"
        testimonials={INVESTOR_VISA_IMMIGRATION_TESTIMONIALS}
      />
      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck />
    </Layout>
  );
}

export default EB5Visa;
